import React from 'react';
import Logo from '../Assets/Logo/logoIconAndTextLight.svg';
import styled from 'styled-components';
import {Colors} from '../Assets/Colors';

const Styles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    padding: 30px;

    .logo {
        width: 100%;
        max-width: 700px;
    }
    .comingSoon {
        color: ${Colors.mintWhite};
        font-family: 'Poiret One';
    }
`;

export const Hero = () => {
    return(
        <Styles>
            <img
                src={Logo}
                className="logo"
                alt="Meridian-fi"
            />
            <h1 className="comingSoon">Coming soon</h1>
        </Styles>
    )
}