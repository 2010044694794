import React from 'react';
//import AlgoInterface from './Components/algoInterface';
import styled from 'styled-components';
import * as THREE from 'three';
//import VANTA from 'vanta';
import NET from './Assets/vanta-master/src/vanta.net.js';
//import {Colors} from './Assets/Colors';
import {Hero} from './Components/Hero';

const Styles = styled.div`
  .background {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
`;

export default class App extends React.Component {
  constructor(){
    super();
    this.vantaRef = React.createRef();
  }

  componentDidMount() {
    this.vantaEffect = NET({
      el: this.vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x0,
      backgroundColor: '#343a40',
      points: 20.00
    })
  }

  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }

  render() {
    return (
      <Styles>
        <div className="background" ref={this.vantaRef}>
          <Hero/>
        </div>
      </Styles>
    );
  }
  
};
